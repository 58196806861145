@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
.Tooltip {
	position: absolute;
	z-index: 3;
	left: calc(100% + 1em);
	top: 0;
	width: 15em;
	border-radius: 0.67em;
	padding: 0.5em 0.5em 0.5em 0.75em;
	background-image: linear-gradient(#EFF2F9, #E0E5F3);
	overflow: hidden;
	color: #3E57AA;
	pointer-events: none;
	@include transition('opacity', 0.2s, 'ease-in-out');
	.Tooltip-text {
		p {
			margin: 0;
			font-size: 0.75em;
			font-weight: normal;
		}

	}
	.Tooltip-table {
		margin-top: 0.5em;
		font-size: 0.75em;
		font-weight: normal;
		.Tooltip-tableRow {
			.Tooltip-tableCell {
				display: inline-block;
				vertical-align: top;
				width: 40%;
				text-align: center;
				span {
					font-size: 1em !important;
					span {
						&.green {color: #4AAC59;}
						&.red {color: #E3535F;}
					}
				}
				&:nth-child(2) {
					width: 60%;
				}
			}
			&.header {
				font-weight: bold;
			}
		}
	}
	.Tooltip-machineWriteOffs {
		font-size: 0.75em;
		font-weight: normal;
		p {margin: 0;}
	}

	&::before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 0.3em;
		height: 100%;
		background-color: #253D8D;
		border-radius: 0.67em 0 0 0.67em;
	}
	&.wash-and-sterilization::before {background-color: #9C65F2;}
	&.formulation-and-stabilization::before {background-color: #E3535F;}
	&.filling-and-inspection::before {background-color: #DF8D25};
	&.office-and-storage::before {background-color: #4AAC59};
}