@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
.InfoPopup {
	position: fixed;
	z-index: 10;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba(white, 0.12);
	// backdrop-filter: blur(4px);
	@include flex('center', 'center');
	@include scale(0);
	@include origin(8em, calc(100% - 3.5em));
	// @include transition('all', 0.3s, ease);
	@include transition('transform', 0.50s, ease);
	&.loading {
		background: #131F47;
	}
	&--show {
		@include scale(1);
		backdrop-filter: blur(4px);
	}
	&.round-5,
	&.round-6,
	&.round-7,
	&.round-8,
	&.round-9 {
		.InfoPopup-content .InfoPopup-body .InfoPopup-text .InfoPopup-gameScore {
			display: block;
		}
	}
	
	.InfoPopup-content {
		position: relative;
		width: 42em;
		height: calc(100% - 10em);
		max-height: 30em;
		color: #3E57AA;
		background-color:#EFF2F9;
		background-image: linear-gradient(#EFF2F9, #E0E5F3);
		border-radius: 0.67em;
		padding: 0 2em;
		&::before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 0.4em;
			background-color: #3E57AA;
			border-radius: 0.67em 0.67em 0 0;
		}
	
		.InfoPopup-header {
			position: relative;
			padding: 3.4em 0em 0.25em 1em;
	
			.InfoPopup-closeBtn,
			.InfoPopup-loadingIcon {
				position: absolute;
				top: 1em;
				right: -1em;
				width: 1em;
				height: 1em;
				background-image: url('../../assets/images/icon-close.svg');
				background-size: 90% auto;
				background-position: center center;
				background-repeat: no-repeat;;
				cursor: pointer;
				&:hover {
					background-size: 100% auto;
				}
			}
			.InfoPopup-loadingIcon { 
				top: 0;
				right: 0;
				width: 3em;
				height: 3em;
				background-image: url('../../assets/images/icon-loading-blue.svg');
				background-size: 80% auto;
				cursor: default;
				&:hover {background-size: 80% auto;}
			}
			.InfoPopup-title {
				font-size: 1.35em;
				font-weight: bold;
			}
		}	

		.InfoPopup-body {
			position: absolute;
			top: 6em;
			left: 1em;
			bottom: 0;
			right: 1em;
			padding: 0 1em 1em 2em;
			overflow: hidden;
			.InfoPopup-text {
				height: calc(100% - 4.5em);
				padding-right: 1em;
				overflow: auto;
				@include custom-scrollbar(0.5em, rgba(#3E57AA, 0.25), #EFF2F9);
				.InfoPopup-fraction {
					text-align: center;
					margin-bottom: 1em;
					span {
						position: relative;
						display: block;
						margin: 0 auto;
						font-weight: normal;
						font-size: 0.75em;
						line-height: 1.2;
						width: 15em;
						&:last-child {
							&:before {
								content: '';
								position: absolute;
								top: 0.05em;
								left: 0;
								width: 100%;
								height: 0;
								border-top: 1px solid #3E57AA;
							}
						}
					}
				}
				.InfoPopup-footnote {
					font-size: 0.75em;	
					font-weight: 400;
					margin-bottom: 2em;
				}
				
				p {
					font-weight: normal;
					line-height: 1.4;
				}
				center {
					margin-bottom: 0.5em;
				}
				h2~p {
					margin-top: 0;
				}
				h2 {
					font-size: 1.167em;
					font-weight: 500;
					margin-bottom: 0;
					&:nth-child(1) {
						margin-top: 0;
					}
				}
				.InfoPopup-gameScore {
					display: none;
				}
				.InfoPopup-reportIcon {
					display: inline-block;
					vertical-align: middle;
					position: relative;
					width: 1.5em;
					height: 1.5em;
					border-radius: 0.33em;
					background-image: linear-gradient(#3E57AA, #253D8D);
					&::after {
						content: '';
						position: absolute;
						width: 100%;
						height: 100%;
						left: 0;
						top: 0;
						background-image: url('../../assets/images/icon-report.svg');
						background-size: auto 55%;
						background-position: center center;
						background-repeat: no-repeat;
					}
				}
			}
			.InfoPopup-startBtn {
				position: absolute;
				bottom: 2em;
				left: calc(50% - 9.5em);
				border-radius: 0.56em;
				width: 15em;
				height: 2.25em;
				color: white;
				background-color: #3E57AA;
				background-image: linear-gradient(#3E57AA, #253D8D);
				cursor: pointer;
				@include flex('center', 'center');

				&:hover {
					background-image: linear-gradient(darken(#3E57AA, 3%), darken(#253D8D, 3%));
				}
			}
		}
	}
}