@import '../styles/variables';
@import '../styles/fonts';
@import '../styles/mixins';
@import '../styles/animations';
.App {
	position: relative;
	height: 100%;
	overflow: auto;

	.Page {
		position: relative;
		height: 100%;
		background-color: #8199BB;
		&:after {
			content: '';
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: black;
			z-index: 11;
			@include opacity(0);
			visibility: hidden;
		}
		&--fadeOut:after {
			@include fadeInOut();
		}
	}
}
