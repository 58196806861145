@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';

.Action {
	position: relative;
	background-image: linear-gradient(rgba(#EFF2F9, 0.5), rgba(#E0E5F3, 0.5));
	border-radius: 0.56em;
	margin: 0.4em 0;
	color: #3E57AA;
	.Action-header {
		height: 2.55em;
		padding: 0 0.8em;
		border-top-left-radius: 0.56em;
		border-top-right-radius: 0.56em;
		cursor: pointer;
		@include flex('left', 'center');
		.Action-title {
			width: calc(100% - 2.55em);
			font-size: 0.85em;
			line-height: 1;
		}
		.Action-toggleBtn {
			position: absolute;
			top: 0;
			right: 0;
			width: 2.55em;
			height: 2.55em;
			background-image: url('../../assets/images/icon-arrow-down.svg');
			background-size: 0.65em auto;
			background-position: center center;
			background-repeat: no-repeat;
		}
	}
	.Action-body {
		height: 0;
		overflow: hidden;
		.Action-description {
			margin: 0.25em 0;
			p {
				display: block;
				font-weight: normal;
				font-size: 0.75em;
				line-height: 1.3em;
				margin: 0;
			}
		}
		.Action-action {
			position: relative;
		}
	}

	&.open {
		background-image: linear-gradient(rgba(#EFF2F9, 0.9), rgba(#E0E5F3, 0.9));
		.Action-header {
			height: 2em;
			.Action-toggleBtn {
				height: 2em;
	
				background-image: url('../../assets/images/icon-arrow-up.svg');
			}
		}
		.Action-body {
			height: auto;
			padding: 0 1em 0.5em 1em;
		}
	}

	&.confirmed {
		color: white;
		background-image: linear-gradient(#3E57AA, #253D8D);
		.Action-header {
			&::before {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 0.3em;
				background-color: #253D8D;
				border-radius: 0.67em 0.67em 0 0;
			}
			.Action-toggleBtn {
				background-image: url('../../assets/images/icon-arrow-down-light.svg');
			}
		}
		&.wash-and-sterilization .Action-header::before {background-color: #9C65F2;}		
		&.formulation-and-stabilization .Action-header::before {background-color: #E3535F;}
		&.filling-and-inspection .Action-header::before {background-color: #DF8D25};
		&.office-and-storage .Action-header::before {background-color: #4AAC59};		
	}

	&.confirmed.open {
		.Action-header {
			padding-top: 0.5em;
			.Action-toggleBtn {
				background-image: url('../../assets/images/icon-arrow-up-light.svg');
			}
		}
	}	
	&.open:not(.confirmed):hover {
		.Action-header {
			color: white;
			background-color: #253D8D;
			background-image: none;
			.Action-toggleBtn {
				background-image: url('../../assets/images/icon-arrow-up-light.svg');	
			}
		}
		&.wash-and-sterilization .Action-header {background-color: #9C65F2;}		
		&.formulation-and-stabilization .Action-header {background-color: #E3535F;}
		&.filling-and-inspection .Action-header {background-color: #DF8D25};
		&.office-and-storage .Action-header {background-color: #4AAC59};		
	}
	&:not(.open):not(.confirmed):hover {
		background-image: none;
		color: white;
		background-color: #253D8D;
		.Action-toggleBtn {
			background-image: url('../../assets/images/icon-arrow-down-light.svg');	
		}
		&.wash-and-sterilization {background-color: #9C65F2;}		
		&.formulation-and-stabilization {background-color: #E3535F;}
		&.filling-and-inspection {background-color: #DF8D25};
		&.office-and-storage {background-color: #4AAC59};	
	}

	&.selected {
		margin: 0 0 0.5em 0;
		border-radius: 0.56em;
		background-image: linear-gradient(#3E57AA, #253D8D);
		color: white;
		&::before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 0.4em;
			background-color: #253D8D;
			border-radius: 0.67em 0.67em 0 0;
		}
		&.wash-and-sterilization::before {background-color: #9C65F2;}
		&.formulation-and-stabilization::before {background-color: #E3535F;}
		&.filling-and-inspection::before {background-color: #DF8D25;}
		&.office-and-storage::before {background-color: #4AAC59;}
	}
}