@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
.UnitCostScorePopup {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	bottom: 0;
	background: rgba(white, 0.12);
	backdrop-filter: blur(4px);
	@include flex('center', 'center');

	.UnitCostScorePopup-content  {
			position: relative;
			width: 40em;
			max-height: 90vh;
			overflow: auto;
			color: white;
			background-color: #131F47;
			border-radius: 0.67em;
			padding: 2em;
	
			.UnitCostScorePopup-rankings {
				text-align: center;
				background-color:darken(#253D8D, 10%);
				.UnitCostScorePopup-cell {
					width: 50%;
					padding: 0.5em;
				}
				.UnitCostScorePopup-rankingsHeader {
					height: 2em;
					padding: 0 1em 0 0.5em;
					background-color: #3E57AA;
					background-image: linear-gradient(#3E57AA, #253D8D);
					border-radius: 0.33em 0.33em 0 0;
					@include flex('center', 'center');
				}
				.UnitCostScorePopup-rankingsBody {
					.UnitCostScorePopup-rankingsRow {
						@include flex('center', 'center');
					}
				}
			}
	}
}