@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
.IntroPopup {
	position: fixed;
	z-index: 10;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba(white, 0.12);
	backdrop-filter: blur(4px);
	@include flex('center', 'center');
	@include transition('opacity', 1s, ease);
	&.loading {
		background: #131F47;
		.IntroPopup-content {opacity: 0;}
	}
	&:not(.show) {
		@include fadeOutClose();
	}
	&.wide {
		.IntroPopup-content {
			padding: 3em 2em 1em 2em;
		}
	}
	
	.IntroPopup-content {
		position: relative;
		width: 33em;
		padding: 3em 4em 1em 4em;
		height: auto;
		color: #3E57AA;
		background-color:#EFF2F9;
		background-image: linear-gradient(#EFF2F9, #E0E5F3);
		border-radius: 0.67em;
		opacity: 1;
		@include transition('opacity', 1s, ease);
		&::before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 0.4em;
			background-color: #3E57AA;
			border-radius: 0.67em 0.67em 0 0;
		}
	
		.IntroPopup-header {
			.IntroPopup-closeBtn {
				position: absolute;
				top: 1em;
				right: 1em;
				width: 1em;
				height: 1em;
				background-image: url('../../assets/images/icon-close.svg');
				background-size: 90% auto;
				background-position: center center;
				background-repeat: no-repeat;;
				cursor: pointer;
				&:hover {
					background-size: 100% auto;
				}
			}
			.IntroPopup-title {
				font-size: 1.38em;
				font-weight: bold;
			}
		}	

		.IntroPopup-body {
			width: 100%;
			padding-top: 1em;
			.IntroPopup-text {
				height: calc(100% - 4.5em);
				padding-bottom: 1em;
				overflow: auto;
				@include custom-scrollbar(0.5em, rgba(#3E57AA, 0.25), #EFF2F9);
				p {
					font-weight: normal;
				}
				h2~p {
					margin-top: 0;
				}
				h2 {
					font-size: 1.167em;
					font-weight: 500;
					margin-bottom: 0;
				}
				.IntroPopup-infoIcon {
					display: inline-block;
					vertical-align: middle;
					position: relative;
					width: 1.5em;
					height: 1.5em;
					border-radius: 0.33em;
					background-image: linear-gradient(#3E57AA, #253D8D);
					&::after {
						content: '';
						position: absolute;
						width: 100%;
						height: 100%;
						left: 0;
						top: 0;
						background-image: url('../../assets/images/icon-info.svg');
						background-size: auto 55%;
						background-position: center center;
						background-repeat: no-repeat;
					}
				}
				.IntroPopup-fraction {
					text-align: center;
					// padding: 0 5em;
					span {
						position: relative;
						display: block;
						margin: 0 auto;
						font-weight: normal;
						font-size: 0.75em;
						line-height: 1.2;
						width: 15em;
						&:last-child {
							&:before {
								content: '';
								position: absolute;
								top: 0.05em;
								left: 0;
								width: 100%;
								height: 0;
								border-top: 1px solid #3E57AA;
							}
						}
					}
				}
				.IntroPopup-footnote {
					font-size: 0.75em;	
					font-weight: 400;
					margin-bottom: 2em;
				}
			}
		}
	}
}