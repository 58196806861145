@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
.Settings {
	height: 100%;
	background-color: #282c34;
	.Settings-panel {
		position: relative;
		height: 100%;
		width: 40em;
		background-color: #253D8D;
		background-image: linear-gradient(to bottom left, #253D8D, #131F47);
		text-align: center;
		padding: 3.5em 2.4em;
		.Settings-logoutBtn {
			position: absolute;
			top: 1em;
			left: 1em;
			width: 2em;
			height: 1.85em;
			background-image: url('../../assets/images/icon-logout.svg');
			background-position: center center;
			background-size: auto 90%;
			background-repeat: no-repeat;
			cursor: pointer;
			&:hover {
				background-size: auto 100%;
			}
		}
		.Settings-statsBtn {
			position: absolute;
			top: 1em;
			right: 9em;
			width: 6em;
			height: 2.25em;
			border-radius: 0.56em;
			margin-bottom: 1em;
			color: white;
			font-weight: normal;
			background-color: #253D8D;
			cursor: pointer;
			background-image: linear-gradient(#3E57AA, #253D8D);
			@include flex('center', 'center');
			@include no-select();
			&:hover {
				background-color: darken(#253D8D, 3%);
				background-image: linear-gradient(darken(#3E57AA, 3%), darken(#253D8D,3%));
			}	
		}
		.Settings-title {
			font-size: 3.5em;
			color: white;
			margin-bottom: 0.5em;
		}
		.Settings-language {
			position: absolute;
			top: 1em;
			right: 1em;
			width: 7em;
		}

		.Settings-createGameBtn {
			width: 16em;
			height: 2.25em;
			border-radius: 0.56em;
			margin: auto auto 1em auto;
			color: white;
			font-weight: normal;
			background-color: #253D8D;
			background-image: linear-gradient(#3E57AA, #253D8D);
			cursor: pointer;
			@include flex('center', 'center');
			&:hover {
				background-color: darken(#253D8D, 3%);
				background-image: linear-gradient(darken(#3E57AA, 3%), darken(#253D8D,3%));
			}
		}
	}

	.Settings-games {
		position: absolute;
		top: 13.5em;
		left: 2.4em;
		right: 2.4em;
		bottom: 2.4em;
		text-align: left;
		overflow: auto;
		@include custom-scrollbar(0.5em, rgba(#3E57AA, 0.25), #EFF2F9);
		.Settings-game {
			display: inline-block;
			width: calc(50% - 1em);
			margin: 0 0.5em 1.5em 0.5em;
			color: white;
			background-color: rgba(#131F47, 0.6);
			border-radius: 0.67em;
			
			.Settings-gameHeader {
				background-color: #253D8D;
				background-image: linear-gradient(#3E57AA, #253D8D);
				border-top-left-radius: 0.67em;
				border-top-right-radius: 0.67em;
				height: 2.56em;
				padding: 0 1em;
				@include flex('space-between', 'center');
				.Settings-gameTitle{
					white-space: nowrap !important;           
					overflow: hidden;         
					word-wrap: normal !important;
					text-overflow: ellipsis !important;
					-ms-text-overflow: ellipsis !important;
				}
				.Settings-gameTitle,
				.Settings-gameDate {
					white-space: nowrap;
				}
				.Settings-gameDate {
					font-size: 0.75em;
				}
			}
			.Settings-gameBody {
				position: relative;
				padding: 0.56em;
				text-align: left;
				.Settings-playBtn {
					width: 100%;
					height: 2.25em;
					border-radius: 0.56em;
					margin-bottom: 1em;
					color: white;
					font-weight: normal;
					background-color: #253D8D;
					cursor: pointer;
					background-image: linear-gradient(#3E57AA, #253D8D);
					@include flex('center', 'center');
					@include no-select();
					&:hover {
						background-color: darken(#253D8D, 3%);
						background-image: linear-gradient(darken(#3E57AA, 3%), darken(#253D8D,3%));
					}					
				}

				.Settings-gameCode,
				.Settings-gameScenario,
				.Settings-gameLanguage {
					margin-top: 0.25em;
					display: inline-block;
					vertical-align: middle;
					color: #5877CA;
					font-weight: normal;
					span {
						width: 5.75em;
						display: inline-block;
						vertical-align: middle;
						font-size: 0.75em;
					}
				}
				.Settings-gameCode {
					margin-top: 0;
				}
				.Settings-gameScenario,
				.Settings-gameLanguage {
					font-size: 0.75em;
					vertical-align: top;
					span {
						font-size: 1em;
						vertical-align: top;
					}
				}
				.Settings-deleteBtn {
					position: absolute;
					bottom: 0.55em;
					right: 0.55em;
					width: 1.5em;
					height: 1.5em;
					background-image: url('../../assets/images/icon-trash.svg');
					background-size: 1.1em auto;
					background-position: bottom right;
					background-repeat: no-repeat;
					cursor: pointer;
					&:hover {
						background-size: 1.15em auto;
					}
				}
			}
			&.started {
				.Settings-gameHeader {
					cursor: pointer;
				}
				.Settings-gameBody {
					.Settings-playBtn {
						background-color: #0080c9;
						&:hover {
							background-color: darken(#0080c9, 5%);
						}
					}
					.Settings-editBtn {
						color: rgba(white, 0.5);
						background-color: #50545a;
						&:hover {
							background-color: darken(#50545a, 3%);
						}
					}
				}
			}
			&.disabled {
				.Settings-gameHeader {
					cursor: default;
					background-color: #213640;
				}
				.Settings-gameBody {
					.Settings-playBtn,
					.Settings-editBtn {
						cursor: not-allowed;
						color: rgba(white, 0.5);
						background-color: #50545a;
						&:hover {background-color: #50545a;}
					}
				}
			}
		}
	}
}

@media only screen and (max-aspect-ratio: 1/1) {
	.Settings {
		font-size: 12px;
		
		.Settings-panel {
			width: 100%;
			padding: 3.5em 2em;
			.Settings-games {
				top: 18em;
				left: 2em;
				right: 2em;
				.Settings-game {
					font-size: 0.75em;
				}
			}
		}
	}
}