@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
.DeleteGamePopup {
	position: fixed;
	top: 0;
	left: 0;
	width: 40em;
	bottom: 0;
	background: rgba(white, 0.12);
	backdrop-filter: blur(4px);
	@include flex('center', 'center');

	.DeleteGamePopup-content  {
			position: relative;
			width: 18em;
			color: white;
			background-color:#131F47;
			border-radius: 0.67em;
		
			.DeleteGamePopup-header {
				position: relative;
				padding: 3.25em 0 1em 0;
				background-image: url('../../assets/images/icon-trash.svg');
				background-size: auto 2em;
				background-position: center top 0.75em; 
				background-repeat: no-repeat;
				.DeleteGamePopup-title {
					font-size: 1.2em;
					text-align: center;
				}
			}	
	
			.DeleteGamePopup-body {
				padding: 0 1em 1em 1em;
				text-align: center;

				.DeleteGamePopup-text {
					padding: 1em;
					font-weight: 400;
					margin-bottom: 1em;
				}
				.DeleteGamePopup-buttons {
					@include flex('space-between');
					.Button {
						width: calc(50% - 0.25em);
					}
				}
			}
	}
}


@media only screen and (max-aspect-ratio: 1/1) {
	.DeleteGamePopup {
		width: 100%;
	}
}