@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
.Button {
	position: relative;
	display: inline-block;
	font-weight: bold;
	text-transform: uppercase;
	text-decoration: none;
	text-align: center;
	color: black;
	background-color: white;
	padding: 0.1em 0.5em;
	border-radius: 0.125em;
	outline: none;
	touch-action: manipulation;
	pointer-events: auto;
	cursor: pointer;
	@include no-select();
	@include opacity(1);
	&:hover,
	&:focus,
	&:active {
		background-color: darken(white, 5%);
	}
	&--isDisabled {
		opacity: 0.4;
		cursor: not-allowed;
		&:hover,
		&:focus,
		&:active {
			opacity: 0.4;
		}
	}
	&--isLoading {
		background-image: url('../../assets/images/icon-loading.svg');
		background-size: auto 80%;
		background-position: center right;
		background-repeat: no-repeat;
	}
	&--cookies {
		position: absolute;
		right: 2em;
		top: 1em;
		width: 7.5em;
		height: 2em;
		color: white;
		font-weight: normal;
		background-color: #253D8D;
		background-image: linear-gradient(#3E57AA, #253D8D);
		border-radius: 0.56em;
		@include flex('center', 'center');
		&:hover {
			background-color: darken(#253D8D, 3%);
			background-image: linear-gradient(darken(#3E57AA, 3%), darken(#253D8D,3%));
		}
	}

	&--cgl {
		position: absolute;
		z-index: 10;
		bottom: 1.56em;
		right: 2em;
		width: 7.5em;
		height: 2.25em;
		background-color: transparent;
		background-image: url('../../assets/images/logo-cgl-white.png');
		background-size: 100% auto;
		background-repeat: no-repeat;
		background-position: bottom center;
		&:hover,
		&:focus,
		&:active {
			background-color: transparent;
		}
	}

	&--logout {
		position: absolute;
		z-index: 10;
		bottom: 4em;
		right: 2em;
		width: 7.5em;
		height: 2.25em;
		color: #3E57AA;
		background-image: linear-gradient(#EFF2F9, #E0E5F3);
		border-radius: 0.56em;
		text-transform: none;
		font-weight: 500;
		@include flex('center', 'center');
		&:hover {
			background-image: linear-gradient(darken(#EFF2F9, 3%), darken(#E0E5F3,3%));
		}
	}
	&--home {
		font-size: 1.11em;
		line-height: 1.8;
		height: 1.8em;
		color: white;
		background-color: transparent;
		border-style: none;
		text-transform: none;
		padding-left: 2.5em;
		background-image: url('../../assets/images/icon-home.svg');
		background-position: left center;
		background-size: auto 100%;
		background-repeat: no-repeat;
		&:hover,
		&:focus,
		&:active {
			background-color: transparent;
		}
	}

	&--popup {
		width: 100%;
		height: 2.25em;
		border-radius: 0.56em;
		margin-bottom: 1em;
		color: white;
		font-weight: normal;
		text-transform: none;
		background-color: #253D8D;
		background-image: linear-gradient(#3E57AA, #253D8D);
		@include flex('center', 'center');
		&.Button--isLoading {
			background-image: url('../../assets/images/icon-loading.svg'), linear-gradient(#3E57AA, #253D8D);
			background-size: auto 80%, 100%;
			background-position: center right, center center;
			background-repeat: no-repeat;
		}
		&.Button--cancel {
			color: #5877CA;
			background-color: transparent;
			background-image: none;
			border: 2px solid #4457A5;
		}
	}

	&--icon {
		position: absolute;
		bottom: 1.56em;
		left: 2em;
		width: 3.11em;
		height: 3.11em;
		border-radius: 0.33em;
		background-image: linear-gradient(#3E57AA, #253D8D);
		cursor: pointer;
		&::after {
			content: '';
			position: absolute;
			width: 100%;
			height: 100%;
			left: 0;
			top: 0;
			background-size: auto 55%;
			background-position: center center;
			background-repeat: no-repeat;
		}
		&:hover {
			@include scale(1.05);
		}
	}
	&--report {
		z-index: 4;
		&::after {
			background-image: url('../../assets/images/icon-report.svg');
		}
	}
	&--info {
		z-index: 4;
		left: 6.11em;
		&::after {
			background-image: url('../../assets/images/icon-info.svg');
		}
	}

	&--blink {
		animation: glowPulse 1.2s ease-in 2s infinite;
	}
}