@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
.Loading {
	height: 100%;
	background-color: #282c34;
	.Loading-panel {
		position: relative;
		height: 100%;
		width: 40em;
		background-color: #253D8D;
		background-image: linear-gradient(to bottom left, #253D8D, #131F47);
		text-align: center;
		padding: 3.5em 2.4em;
		@include flex('center', 'center');

		.Loading-logoutBtn {
			position: absolute;
			top: 1em;
			left: 1em;
			width: 2em;
			height: 1.85em;
			background-image: url('../../assets/images/icon-logout.svg');
			background-position: center center;
			background-size: auto 90%;
			background-repeat: no-repeat;
			cursor: pointer;
			&:hover {
				background-size: auto 100%;
			}
		}
		.Loading-content {
			.Loading-circles {
				width: 100%;
				@include flex('center', 'center');
				.Loading-circle {
					width: 2em;
					height: 2em;
					margin: 0 0.5em;
					background-color: rgba(#5877CA, 1);
					border-radius: 2em;
				}
			}
			.Loading-text {
				margin-top: 1em;
				color: white;
			}
		}
	}
}

@for $i from 1 to 4 {
	.Loading-circle:nth-child(#{$i}) {
		animation: loading 600ms ease #{300 * $i}ms alternate infinite;
	}
}
@keyframes loading {
	to {
		background-color: rgba(#5877CA, 0.1);
	}
}

@media only screen and (max-aspect-ratio: 1/1) {
	.Loading .Loading-panel {
		width: 100%;
	}
}