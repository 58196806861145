@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
.Background {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;

	.Background-objects {
		position: absolute;
		top: 0;
		right: 0;
		width: 100%;
		height: 100%;
		overflow: hidden;
		text-align: right;
		svg {
			height: 100%;
		}
	}

	.Background-overlay {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-size: cover;
		background-position: top center;
		background-image: url('../../assets/images/overview/overlay.svg');
	}
}