@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
.IllustrationPopup {
	position: fixed;
	z-index: 10;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba(white, 0.12);
	backdrop-filter: blur(4px);
	@include flex('center', 'center');
	@include transition('opacity', 1s, ease);
	&.loading {
		background: #131F47;
		.IllustrationPopup-content {opacity: 0;}
	}
	&:not(.show) {
		@include fadeOutClose();
	}
	
	.IllustrationPopup-content {
		position: relative;
		width: 40em;
		height: 26em;
		color: #3E57AA;
		background-color:#EFF2F9;
		background-image: linear-gradient(#EFF2F9, #E0E5F3);
		border-radius: 0.67em;
		opacity: 1;
		@include transition('opacity', 1s, ease);
		&::before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 0.4em;
			background-color: #3E57AA;
			border-radius: 0.67em 0.67em 0 0;
		}

		.IllustrationPopup-text {
			position: relative;
			width: 100%;
			height: 100%;
			padding: 3em 18.8em 1em 4em;
			background-repeat: no-repeat;
			background-size: 15em auto;
			background-position: center right 1.5em;
			p {font-size: 1.16em;}
			&.round-1 {
				background-image: url('../../assets/images/rounds/round-1.svg');
				background-position: top 4em right 1.5em;
			}
			&.round-2 {
				background-image: url('../../assets/images/rounds/round-2.svg');
				background-size: 16em auto;
				background-position: center right 1em;
			}
			&.round-3 {
				background-image: url('../../assets/images/rounds/round-3.svg');
			}
			&.round-4 {
				background-size: 17em auto;
				background-position: top 5.5em right 1.5em;
				background-image: url('../../assets/images/rounds/round-4.svg');
			}
			&.round-5 {
				background-image: url('../../assets/images/rounds/round-5.svg');
			}
			&.round-6 {
				background-size: 18em auto;
				background-position: top 6em right 1.5em;
				background-image: url('../../assets/images/rounds/round-6.svg');
			}
			&.round-7 {
				background-image: url('../../assets/images/rounds/round-7.svg');
			}
			&.round-8 {
				background-size: 16em auto;
				background-image: url('../../assets/images/rounds/round-8.svg');
			}
			.IllustrationPopup-animation {
				width: 100%;
				height: 10em;
				background-image: url('../../assets/images/rounds/animation.svg');
				background-size: contain;
				background-repeat: no-repeat;
				background-position: center center;
			}
		}
	}
}