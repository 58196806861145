@import 'styles/variables';
@import 'styles/fonts';
@import 'styles/mixins';
@import 'styles/animations';
html {
  position: relative;
  font-size: 1.172vw; // 18px at screen width ~1536px
  height: 100%;
}

body {
  position: relative;
  font-family: 'Roboto', sans-serif;
  font-size: 1em;
  font-weight: 500;
  margin: 0;
  padding: 0;
  height: 100%;
  min-height: calc(max(40em, 100%));
  width:100%;
}

#root {
  height: 100%;
}

* {box-sizing: border-box;
  font-family: 'Roboto', sans-serif;}

button {
  background-image: none;
  -webkit-tap-highlight-color: transparent;
  @include no-select();
  &:focus {
    outline: 0;
  }
  &::-moz-focus-inner {
    border: 0;
  }
}

ul, ol {
	padding-inline-start: 1.5em;
	margin: 0;
}

input,
input:focus,
textarea,
textarea:focus {
  font-size: inherit;
  @include appearance(none);
}

// @media (min-width: 320px) {
//   html {
//     font-size: calc(18 * ((100vw / 360)));
//   }
// }

// @media (min-aspect-ratio: "9/16") {
//   html {
//     font-size: calc(18 * ((100vh / 640)));
//   }
// }
