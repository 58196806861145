@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
.Select {
	position: relative;
	width: 100%;
	height: 2.25em;
	border-radius: 0.56em;
	background-color: #C0CBE1;
	color: #253D8D;
	font-weight: normal;
	text-align: left;
	cursor: pointer;
	&.open {
		border-radius: 0.56em 0.56em 0 0;
		.Select-dropdown {
			height: auto;
			border-radius: 0 0 0.56em 0.56em;
			border-top: 1px solid #253D8D;
		}
	}
	&.disabled {
		cursor: default;
		color: black;
		background-color: rgb(225, 225, 225);
		.Select-button {display: none;}
	}
	&.createGameScenario {
		margin-bottom: 2em;
	}

	&.game {
		display: inline-block;
		width: 5em;
		height: 1em;
		color: #5877CA;
		background-color: transparent;
		text-align: left;
		.Select-panel {
			padding: 0;
		}
		.Select-button {
			background-position: top 0.25em right 0;
		}
		.Select-dropdown { 
			top: 1.25em;
			background-color: #131F47;
			border-radius: 0;
			.Select-option { 
				padding: 0.25em 0 0.75em 0;
				height: 1.5em;
				border-radius: 0 0 0.25em 0.25em;
				&:hover {
					background-color: rgba(#5877CA, 0.1);
				}
			}
		}
	}
	
	.Select-panel {
		height: 100%;
		padding: 0.56em 1em;
	}
	
	.Select-selected {
		span {
			line-height: 1;
		}
	}
	.Select-button {
		position: absolute;
		top: 0;
		right: 0;
		width: 2.25em;
		height: 2.25em;
		background-image: url('../../assets/images/icon-arrow-down.svg');
		background-position: top 0.9em right 0.75em;
		background-size: auto 0.5em;
		background-repeat: no-repeat;
	}

	.Select-dropdown {
		position: absolute;
		left: 0;
		top: 2.25em;
		width: 100%;
		height: 0;
		background-color: white;
		overflow: hidden;
		z-index: 2;
		@include transition('height', 0.3s, 'ease');
		
		.Select-option {
			cursor: pointer;
			position: relative;			
			padding: 0.56em 1em;
			height: 2.25em;
			span {
				line-height: 1;
			}
			&:hover {
				background-color: rgba(#253D8D, 0.1);
			}
			&--disabled {
				cursor: not-allowed;
				opacity: 0.5;
				&:hover {
					background-color: white;
				}
			}
		}
	}
}