@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
.Area {
	position: relative;
	min-height: 100%;
	height: 100%;
	.Area-objects {
		position: relative;
		min-height: 100%;
		height: 100%;
		overflow: hidden;
		g {
			position: relative;
		}
		image {
			position: absolute;
			&.ready {
				// transition: transform 0.25s ease-out;
				transition: transform 0.4s ease-out;
			}
		}
	}
	.Area-rounds {
		position: absolute;
		top: 4.7em;
		right: 2.25em;
		width: 15.75em;
		@include flex('space-between', 'center');
		.Area-round {
			width: 1.67em;
			height: 1.67em;
			border-radius: 2em;
			color: #3E57AA;
			background-color: rgba(#E0E5F3, 0.3);
			@include flex('center', 'center');
			span {
				font-size: 0.88em;
			}
			&.selected {
				background-color: #E0E5F3;
				font-weight: 700;
			}
		}
	}
}