@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';

.Login {
	height: 100%;
	width: 100%;
	background-color: #282c34;
	overflow: hidden;
	.Login-panel {
		position: relative;
		height: 100%;
		width: 40em;
		background-color: #253D8D;
		background-image: linear-gradient(to bottom left, #253D8D, #131F47);
		text-align: center;
		padding: 2em 6em;
		@include flex('center', 'center');
		.Login-language {
			position: absolute;
			top: 1em;
			right: 1em;
			width: 6em;
		}
	}
	.Login-wrap {
		position: relative;
		width: 100%;
		height: 100%;
		.Login-title {
			font-size: 3.5em;
			color: white;
			margin-bottom: 0.5em;
		}
	}


	.Login-box {
		position: relative;
		display: inline-block;
		text-align: left;
		width: calc(100% - 12em);
		height: calc(100% - 6em);
		max-height: 100%;
		.Login-tabContainer {
			width: 100%;
			height: 2.56em;
			background-color: rgba(#131F47, 0.6);
			border-radius: 0.67em;
			padding: 0.167em;
			margin-bottom: 1em;
			@include flex('center', 'center');
			.Login-tabButton {
				height: 100%;
				width: 50%;
				border-radius: 0.56em;
				color: #3E57AA;
				cursor: pointer;
				@include flex('center', 'center');
				span {
					font-size: 1.2em;
					font-weight: normal;
				}
				&.active {
					background-color: #253D8D;
					background-image: linear-gradient(#3E57AA, #253D8D);
					color: white;
				}
			}
		}
		.Login-formContainer {
			height: calc(100% - 3.56em);
			.LoginFacilitator,
			.LoginGroup,
			.LoginChooseGroup {
				width: 100%;
				height: 100%;
				text-align: left;
				.Login-info {
					color: white;
					font-size: 0.95em;
					font-weight: normal;
					margin-top: 0;
					line-height: 1.5;
					margin-bottom: 1.5em;
				}
				.Login-form {
					position: relative;
					&.feedback {
						.Login-infoLink {
							color: white;
							cursor: pointer;
							&:hover {text-decoration: underline;}
						}
					}
					input {
						display: block;
						width: 100%;
						height: 2.25em;
						border: none;
						border-radius: 0.56em;
						padding: 0.56em 1em;
						margin: auto;
						margin-bottom: 1em;
						background-color: #C0CBE1;
						color: #253D8D;
						font-weight: normal;
						&::placeholder {color: #3E57AA;}
					}
					.Button {
						width: 100%;
						height: 2.25em;
						border-radius: 0.56em;
						margin-bottom: 1em;
						color: white;
						font-weight: normal;
						background-color: #253D8D;
						background-image: linear-gradient(#3E57AA, #253D8D);
						@include flex('center', 'center');
						&--isLoading {
							background-image: url('../../assets/images/icon-loading.svg'), linear-gradient(#3E57AA, #253D8D);
							background-size: auto 80%, 100%;
							background-position: center right, center center;
							background-repeat: no-repeat;
						}
					}
				}	
				.Login-groups {
					width: 100%;
					height: 100%;
					overflow: auto;
					white-space:nowrap;
					.Login-groupSelectBtn,
					.Login-newGroupBtn {
						width: 100%;
						height: 2.25em;
						border-radius: 0.56em;
						margin-bottom: 1em;
						color: white;
						font-weight: normal;
						background-color: #253D8D;
						background-image: linear-gradient(#3E57AA, #253D8D);
						@include flex('center', 'center');
						cursor: pointer;
						&.loading {
							background-image: url('../../assets/images/icon-loading.svg'), linear-gradient(#3E57AA, #253D8D);
							background-size: auto 80%, 100%;
							background-position: center right, center center;
							background-repeat: no-repeat;
						}
					}
				}
				.Login-resetPasswordBtn,
				.Login-createUserBtn {
					width: 100%;
					line-height: 1.5em;
					font-size: 0.9em;
					font-weight: normal;
					text-align: center;
					color: #C0CBE1;
					cursor: pointer;
				}
			}
		}
	}
	
}

.Login-buttons {
	@include flex('space-between', 'center');
	.Button.Button--login { 
		margin: 0 0.5em;
		&:nth-child(1) {margin-left: 0;}
		&:nth-last-child(1) {margin-right: 0;}
	}
}


.Login-errorMessage {
	height: 1.5em;
	margin-bottom: 0.6em;
	padding: 0 1em;
	font-size: 0.85em;
	color: red;
	text-align: center;
}

@media only screen and (max-aspect-ratio: 1/1) {

	.Login {
		font-size: 12px;
		.Login-panel {
			width: 100%;
			padding: 0 4em;
			padding-top: 4em;
		}
		.Login-box {
			width: 100%;
			height: auto;
		}
	}
}