@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
.Overview {
	position: relative;
	min-height: 100%;
	height: 100%;

	.Overview-objects {
		position: relative;
		min-height: 100%;
		height: 100%;
		overflow: hidden;
		g {
			position: relative;
		}
	}

	.Overview-overlay {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-size: cover;
		background-position: top center;
		background-image: url('../../assets/images/overview/overlay.svg');
	}
	&.wash-and-sterilization {
		.Overview-overlay {background-image: url('../../assets/images/overview/overlay-1.svg');}
	}
	&.formulation-and-stabilization {
		.Overview-overlay {background-image: url('../../assets/images/overview/overlay-2.svg');}
	}
	&.filling-and-inspection {
		.Overview-overlay {background-image: url('../../assets/images/overview/overlay-3.svg');}
	}
	&.office-and-storage {
		.Overview-overlay {background-image: url('../../assets/images/overview/overlay-4.svg');}
	}

	.Overview-hoverAreas {
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		overflow: hidden;
		text-align: center;
		svg {
			polygon {
				width: 100%;
				fill: none;
				pointer-events: visible;
			}
		}
	}

	.Overview-areaInfo {
		position: absolute;
		width: 14.5em;
		pointer-events: none;
		.Overview-panel {
			position: relative;
			background-image: linear-gradient(#EFF2F9, #E0E5F3);
			border-radius: 0.56em;
			padding: 0.6em 0.3em 0.4em 0.3em;
			&::before {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 0.4em;
				background-color: #253D8D;
				border-radius: 0.67em 0.67em 0 0;
			}
			&::after {
				content: '';
				position: absolute;
				top: calc(100% - 1px);
				left: calc(50% - 0.65em);
				width: 0;
				height: 0;
				border-left: 0.65em solid transparent;
				border-right: 0.65em solid transparent;
				border-top: 0.9em solid #E0E5F3;
			}
			.Overview-panelRow {
				color: #3E57AA;
				padding: 0.3em 0.4em;
				@include flex('space-between', 'center');
				span {
					font-weight: normal;
					font-size: 0.70em;
					&:nth-child(2) {
						text-align: right;
					}
				}
				&.bold span {
					font-weight: 700;
				}
				&.title,
				&.footer {
					border-bottom: 1px solid #C0CFE1;
					span {
						font-size: 0.70em;
					}
				}
				&.footer {
					margin-top: 0.25em;
					padding: 0.25em 0.5em;
					background-color: rgba(#253D8D, 0.25);
					border-radius: 0 0 0.67em 0.67em;
					span {
						font-weight: 700;
					}

				}

			}
		}
		.Overview-areaTitle {
			color: #E2E7F4;
			text-align: center;
			margin-top: 1.8em;
			span {
				font-size: 1.15em;
			}
			&.hover {
				color: #253D8D;
			}
			
		}
		&.wash-and-sterilization {
			top: 11em;
			left: 4em;
			.Overview-panel {
				&::before {background-color: #9C65F2;}
				.Overview-panelRow.footer {background-color: rgba(#9C65F2, 0.25);}
			}
		}
		&.formulation-and-stabilization {
			top: 50%;
			left: 25%;
			.Overview-panel {
				&::before {background-color: #E3535F;}
				.Overview-panelRow.footer {background-color: rgba(#E3535F, 0.25);}
			}
		}
		&.filling-and-inspection {
			top: 25%;
			left: 50%;
			.Overview-panel {
				&::before {background-color: #DF8D25;}
				.Overview-panelRow.footer {background-color: rgba(#DF8D25, 0.25);}
			}
		}
		&.office-and-storage {
			width: 16em;
			top: calc(50% + 7em);
			right: 10em;
			.Overview-panel {
				&::before {background-color: #4AAC59;}
				.Overview-panelRow:not(.title):not(.footer) {
					padding: 0.15em 0.4em 0.1em 0.4em;
				}
				.Overview-panelRow.footer {background-color: rgba(#4AAC59, 0.25);}
			}
		}
	}

}