@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';

.CostsAndProduction {
	position: absolute;
	top: 6.15em;
	left: 2em;

	.CostsAndProduction-box {
		position: relative;
		width: 15.25em;
		color: #3E57AA;
		background-image: linear-gradient(#EFF2F9, #E0E5F3);
		border-radius: 0.67em;
		padding: 0.75em 0.5em 0.5em 0.5em;
		&.costs {margin-bottom: 1em;}
		&.production {
			.CostsAndProduction-section.production {
				.CostsAndProduction-sectionRow.rampup {
					@include flex('space-between', 'flex-start');
					span:last-of-type {
						text-align: right;
						span {
							font-size: 0.75em;
							line-height: 1;
							margin-top: -0.2em;
							padding-bottom: 0.2em;
							display: block;
						}
						
						white-space: pre-wrap;
						// padding-top: 1.1em;
					}
				}
			}
		}
		&.balance {
			// width: 16.9em;
			.CostsAndProduction-section.capacity {
				border-bottom: none;
			}
			.CostsAndProduction-section:not(.gameover) {
				// .CostsAndProduction-sectionRow:last-of-type {
				// 	span:first-of-type {
				// 		white-space: pre-wrap;
				// 		line-height: 1.1em;
				// 	}
				// 	span:last-of-type {
				// 		text-align: right;
				// 		padding-top: 1.1em;
				// 	}
				// }
				// &.capacity {
				// 	.CostsAndProduction-sectionRow:last-of-type {
				// 		span:last-of-type {
				// 			width: 13em;
				// 		}
				// 	}
				// }
				&.throughput {
					.CostsAndProduction-sectionRow:last-of-type {
						span:first-of-type {
							white-space: pre-wrap;
							line-height: 1.1em;
						}
						span:last-of-type {
							text-align: right;
							padding-top: 1.1em;
							width: 100%;
							white-space: nowrap;
						}
					}
				}
			}
		}
		&::before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 0.4em;
			background-color: #253D8D;
			border-radius: 0.67em 0.67em 0 0;
		}
	
		.CostsAndProduction-title {
			line-height: 1;
			font-weight: bold;
			border-bottom: 1px solid #C0CFE1;
			padding: 0.5em;
			span {
				font-size: 0.75em;
				font-weight: normal;
			}
		}
		.CostsAndProduction-section {
			padding: 0.25em 0;
			&.capacity {
				border-top: 1px solid #C0CFE1;
				border-bottom: 1px solid #C0CFE1;
			}
			&.total,
			&.throughput {
				.CostsAndProduction-sectionRow {
					background-color: rgba(#253D8D, 0.25);
					border-radius: 0 0 0.67em 0.67em;
					padding: 0.25em 0.5em;
					span {
						font-size: 0.83em;
						font-weight: bold;
					}
				}
			}
			.CostsAndProduction-sectionRow {
				position: relative;
				padding: 0 0.5em;
				@include flex('space-between', 'center');
				@include transition('background', 0.2s, 'ease-in-out');
				span {
					font-size: 0.78em;
					font-weight: normal;
					line-height: 1.6em;
				}
				.Tooltip {
					opacity: 0;
				}
				&.tooltip:hover {
					.Tooltip {opacity: 1;}
					font-weight: bold;
					color: white;
					background-color: #253D8D;
				}
				&.title {
					span:nth-of-type(1) {
						font-size: 0.83em;
						font-weight: 700;
					}
				}
				&.animate {
					font-weight: bold;
					color: white;
					background-color: #253D8D;
				}
			}
		}
	}
	&.wash-and-sterilization .CostsAndProduction-box {
		&::before {background-color: #9C65F2;}
		.CostsAndProduction-section.total .CostsAndProduction-sectionRow,
		.CostsAndProduction-section.throughput .CostsAndProduction-sectionRow{
			background-color: rgba(#9C65F2, 0.25);
		}
		.CostsAndProduction-section .CostsAndProduction-sectionRow.animate,
		.CostsAndProduction-section .CostsAndProduction-sectionRow.tooltip:hover {
			background-color: #9C65F2;
		}
	}
	&.formulation-and-stabilization .CostsAndProduction-box {
		&::before {background-color: #E3535F;}
		.CostsAndProduction-section.total .CostsAndProduction-sectionRow,
		.CostsAndProduction-section.throughput .CostsAndProduction-sectionRow{
			background-color: rgba(#E3535F, 0.25);
		}
		.CostsAndProduction-section .CostsAndProduction-sectionRow.animate,
		.CostsAndProduction-section .CostsAndProduction-sectionRow.tooltip:hover {
			background-color: #E3535F;
		}
	}

	&.filling-and-inspection .CostsAndProduction-box {
		&::before {background-color: #DF8D25};
		.CostsAndProduction-section.total .CostsAndProduction-sectionRow,
		.CostsAndProduction-section.throughput .CostsAndProduction-sectionRow{
			background-color: rgba(#DF8D25, 0.25);
		}
		.CostsAndProduction-section .CostsAndProduction-sectionRow.animate,
		.CostsAndProduction-section .CostsAndProduction-sectionRow.tooltip:hover {
			background-color: #DF8D25
		}
	}

	&.office-and-storage .CostsAndProduction-box {
		&::before {background-color: #4AAC59};
		.CostsAndProduction-section.total .CostsAndProduction-sectionRow,
		.CostsAndProduction-section.throughput .CostsAndProduction-sectionRow{
			background-color: rgba(#4AAC59, 0.25);
		}
		.CostsAndProduction-section .CostsAndProduction-sectionRow.animate,
		.CostsAndProduction-section .CostsAndProduction-sectionRow.tooltip:hover {
			background-color: #4AAC59
		}
	}
}

