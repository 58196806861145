@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';

.AvailableActions {
	position: absolute;
	z-index: 1;
	width: 15.56em;
	padding: 0.45em;
	border-radius: 0.33em;
	.AvailableActions-bgColor {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 0;
		border-radius: 0.33em;
		background-color: rgba(#284B83, 0.4);
		transition: all 0.15s ease-in;
	}
	.AvailableActions-header {
		position: relative;
		cursor: pointer;
		height: 2em;
		.AvailableActions-toggleBtn {
			position: relative;
			display: inline-block;
			vertical-align: middle;
			width: 2em;
			height: 2em;
			border-radius: 2em;
			background-color: #3E57AA;
			&::after {
				content: '';
				position: absolute;
				top: calc(50% - 0.325em);
				left: calc(50% - 0.325em);
				width: 0.65em;
				height: 0.65em;
				background-image: url('../../assets/images/icon-plus.svg');
				background-size: contain;
				background-position: center center;
				background-repeat: no-repeat;
				@include origin(50%, 50%);
				@include rotate(0deg);
				@include transitionTransform(0.3s, 'ease-in-out');
			}
		}
		.AvailableActions-title {
			display: inline-block;
			vertical-align: middle;
			color: #3E57AA;
			margin-left: 0.6em;
			@include no-select();
		}
	}
	.AvailableActions-body {
		transition: height 0.15s ease-in;
		height: 0;
		overflow: hidden;
	}

	&.personnel {top: 10.25em; left: 18em;}
	&.machines {top: 6.5em; left: 34.33em;}
	&.materials {top: 11.25em; left: 50.72em;}
	&.open {
		z-index: 2;
		.AvailableActions-bgColor {
			width: 100%;
			height: 100%;
			transition: all 0.25s ease-out;
		}
		.AvailableActions-header {
			.AvailableActions-toggleBtn::after {
				@include rotate(135deg);
			}
			.AvailableActions-title {
				color: white;
			}
		}
		.AvailableActions-body {
			height: auto;
			transition: height 0.15s ease-out;
			&.animate-1 {height: calc(3.35em);}
			&.animate-2 {height: calc(2 * 3.35em);}
			&.animate-3 {height: calc(3 * 3.35em);}
			&.animate-4 {height: calc(4 * 3.35em);}
			&.animate-5 {height: calc(5 * 3.35em);}
		}
	}

	&.wash-and-sterilization {
		&.materials,
		&.personnel {
			.AvailableActions-header	.AvailableActions-title {color: white;}
		}
		.AvailableActions-header .AvailableActions-toggleBtn {background-color: #9C65F2;}
	}
	&.formulation-and-stabilization {
		&.personnel {top: 6em;}
		&.machines {top: 9em; left: 34.33em;}
		&.materials {top: 6.5em; left: 50.72em;}
		.AvailableActions-header .AvailableActions-toggleBtn {background-color: #E3535F;}
	}
	&.filling-and-inspection {
		&.personnel {top: 11em;}
		&.materials {
			top: 5em;
			.AvailableActions-header	.AvailableActions-title {color: white;}
		}
		&.machines {top: 8em;}
		.AvailableActions-header .AvailableActions-toggleBtn {background-color: #DF8D25;}
	}
	&.office-and-storage {
		&.office {
			top: 5em; left: 18em;
			.AvailableActions-header	.AvailableActions-title {color: white;}
		}
		&.storage {top: 16em; left: 50.72em;}
		&.initiatives {top: 8em; left: 34.33em;}
		.AvailableActions-header .AvailableActions-toggleBtn {background-color: #4AAC59;}
	}
}