@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';

.ConfirmButton {
	width: 1.4em;
	height: 1.4em;
	border: 3px solid rgba(#3E57AA, 0.5);
	border-radius: 0.25em;
	margin-left: 0.25em;
	cursor: pointer;
	&.checked {
		background-color: #C0CFE1;
		border-style: none;
		background-image: url('../../assets/images/icon-check.svg');
		background-size: 70% auto;
		background-position: center center;
		background-repeat: no-repeat;
	}
	&.disabled {
		cursor: not-allowed;
	}
}