@-webkit-keyframes fadeIn {
	0% {@include opacity(0);}
	100% {@include opacity(1);}
}
@keyframes fadeIn {
	0% {@include opacity(0);}
	100% {@include opacity(1);}
}


@-webkit-keyframes backgroundPulse1 {
	0% {background-color: rgba(#9C65F2, 0);}
	50% {background-color: rgba(#9C65F2, 0.25);}
	100% {background-color: rgba(#9C65F2, 0);}
}
@keyframes backgroundPulse1 {
	0% {background-color: rgba(#9C65F2, 0);}
	50% {background-color: rgba(#9C65F2, 0.25);}
	100% {background-color: rgba(#9C65F2, 0);}
}

@-webkit-keyframes backgroundPulse2 {
	0% {background-color: rgba(#E3535F, 0);}
	50% {background-color: rgba(#E3535F, 0.25);}
	100% {background-color: rgba(#E3535F, 0);}
}
@keyframes backgroundPulse2 {
	0% {background-color: rgba(#E3535F, 0);}
	50% {background-color: rgba(#E3535F, 0.25);}
	100% {background-color: rgba(#E3535F, 0);}
}

@-webkit-keyframes backgroundPulse3 {
	0% {background-color: rgba(#DF8D25, 0);}
	50% {background-color: rgba(#DF8D25, 0.25);}
	100% {background-color: rgba(#DF8D25, 0);}
}
@keyframes backgroundPulse3 {
	0% {background-color: rgba(#DF8D25, 0);}
	50% {background-color: rgba(#DF8D25, 0.25);}
	100% {background-color: rgba(#DF8D25, 0);}
}

@-webkit-keyframes backgroundPulse4 {
	0% {background-color: rgba(#4AAC59, 0);}
	50% {background-color: rgba(#4AAC59, 0.25);}
	100% {background-color: rgba(#4AAC59, 0);}
}
@keyframes backgroundPulse4 {
	0% {background-color: rgba(#4AAC59, 0);}
	50% {background-color: rgba(#4AAC59, 0.25);}
	100% {background-color: rgba(#4AAC59, 0);}
}


@-webkit-keyframes glowPulse {
	0% {@include box-shadow(0, 0, 0.9em, 0.1em, white);}
	50% {@include box-shadow(0, 0, 0.5em, 0em, white);}
	100% {@include box-shadow(0, 0, 0.9em, 0.1em, white);}
}
@keyframes glowPulse {
	0% {@include box-shadow(0, 0, 0.9em, 0.1em, white);}
	50% {@include box-shadow(0, 0, 0.5em, 0em, white);}
	100% {@include box-shadow(0, 0, 0.9em, 0.1em, white);}
}