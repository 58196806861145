@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
.Game {
	height: 100%;
	background-color: #282c34;
	.Game-panel {
		position: relative;
		height: 100%;
		width: 40em;
		color: white;
		background-color: #253D8D;
		background-image: linear-gradient(to bottom left, #253D8D, #131F47);
		text-align: left;
		padding: 5em 3.5em;
		.Game-title {
			text-align: center;
			font-size: 2.5em;
			margin-bottom: 0.5em;
		}
		.Game-backBtn {
			position: absolute;
			top: 1em;
			left: 1em;
			width: 2em;
			height: 1.85em;
			background-image: url('../../assets/images/icon-home.svg');
			background-position: center center;
			background-size: auto 90%;
			background-repeat: no-repeat;
			cursor: pointer;
			&:hover {
				background-size: auto 100%;
			}
		}

		.Game-info {
			@include flex('space-between', 'center');
			div {
				background: linear-gradient(#3E57AA, #253D8D);
				padding: 0.25em 0.5em;
				width: 8em;
				font-weight: bold;
				font-size: 1.25em;
				text-align: center;
				border-radius: 0.25em;
				&:nth-of-type(1) {
					width: 10em;
				}
				&:nth-of-type(3) {
					width: 6em;
				}
				span {
					display: block;
					font-weight: normal;	
					font-size: 0.7em;
					text-align: left;
					margin-bottom: 0.2em;
				}
			}
		}
		.Game-buttons {
			margin: 2em 0;
			@include flex('center', 'center');
			.Game-nextPhaseBtn,
			.Game-scorePopupBtn,
			.Game-pauseBtn {
				margin: 0 1em;
				width: 10em;
				height: 2.25em;
				border-radius: 0.56em;
				color: white;
				font-weight: normal;
				text-transform: none;
				background-color: #253D8D;
				background-image: linear-gradient(#3E57AA, #253D8D);
				cursor: pointer;
				@include flex('center', 'center');
				&:hover {
					background-color: darken(#253D8D, 3%);
					background-image: linear-gradient(darken(#3E57AA, 3%), darken(#253D8D,3%));
				}
			}
			.Game-pauseBtn:not(.paused) {
				color: #5877CA;
				background-color: transparent;
				background-image: none;
				border: 2px solid #4457A5;
			}
		}

		.Game-groups {
			position: relative;
			height: calc(100% - 10em);
			border-radius: 0.33em 0.33em;
			padding-bottom: 0.5em;
			&.phase-1:after {
				content: '';
				position: absolute;
				left: calc(0.55 * 31.5em + 0.5em + 1px);
				top: 0;
				height: 100%;
				width: 0;
				border-left: 1px solid rgba(white, 0.3);
			}
			.Game-groupsHeader {
				position: relative;
				width: 33em;
				padding: 0 1em 0 0.5em;
				background-color: #3E57AA;
				background-image: linear-gradient(#3E57AA, #253D8D);
				border-radius: 0.33em 0.33em 0 0;
				@include flex('center', 'center');
				.Game-groupsName {
					width: calc(0.2 * 31.5em);
				}
				.Game-groupsPhase {
					width: calc(0.35 * 31.5em);
					text-align: center;
					padding: 0.5em 0;
					.Game-groupsPhaseTitle {
						font-size: 1.25em;
						font-weight: bold;
						margin-bottom: 0.1em;
					}
					.Game-groupsPhaseRounds {
						@include flex('center', 'center');
						span {
							display: inline-block;
							width: calc(0.25 * 32em);
						}
					}
				}
				.Game-score {
					width: calc(0.1 * 31.5em);
					text-align: center;
					padding: 0.5em 0;
				}
			}
			.Game-groupContainer {
				position: relative;
				padding: 1.6em 0 0.5em 0;
				overflow: auto;
				background-color: #131F47;
				border-radius: 0 0 0.33em 0.33em;
				@include custom-scrollbar(0.5em, rgba(#3E57AA, 0.25), #EFF2F9);
				height: calc(100% - 3.3em);
				.Game-group {
					position: relative;
					width: calc(33em - 0.5em);
					padding: 0.25em 0.5em 0.25em 0.5em;
					color: rgba(white, 0.3);
					background-color: #131F47;
					
					@include flex('flex-start', 'center');
					.Game-groupWrap {
						position: relative;
						height: 2em;
						display: inline-block;
						border: 1px solid #4457A5;
						border-radius: 0.56em;
						@include flex('flex-start', 'center');
						.Game-groupTitle {
							width: calc(0.2 * 31.5em);
							text-align: center;
							padding: 0.25em 0;
							box-sizing: content-box;
							overflow:  hidden;
							white-space: nowrap;
						}
						.Game-groupRoundStatus {
							position: relative;
							width: calc(0.35 * 0.25 * 31.5em);
							height: 100%;
							text-align: center;
							background-size: auto 1em;
							background-position: center center;
							background-repeat: no-repeat;
							box-sizing: content-box;
							&.current {
								background-image: url('../../assets/images/icon-hourglass.svg');
							}
							&.done {
								cursor: pointer;
								background-image: url('../../assets/images/icon-check-light.svg');
								&:hover {
									.Game-groupRoundStatusTooltip {
										display: block;
									}
								}
							}
							
						}
						.Game-groupRoundStatusTooltip {
							display: none;
							position: absolute;
							top: -1.9em;
							left: calc(0.5 * 0.35 * 0.25 * 31.5em - 4.5em);
							width: 9em;
							padding: 0.25em 0.5em;
							border-radius: 0.25em;
							background-image: linear-gradient(#3E57AA, #253D8D);
							span {
								font-size: 0.75em;
							}
							&::after {
								content: '';
								position: absolute;
								top: calc(100% - 1px);
								left: calc(50% - 0.45em);
								width: 0;
								height: 0;
								border-left: 0.45em solid transparent;
								border-right: 0.45em solid transparent;
								border-top: 0.6em solid #253D8D;
							}
						}
						.Game-unitScoreCost {
							width: calc(0.1 * 31.5em);	
							height: 100%;
							@include flex('center', 'center');
						}
					}
					&.active {
						color: white;
						.Game-groupWrap {
							border: 1px solid #697abe;
						}
					}
				}
			}
			
		}
		.Game-downloadBtn {
			border-style: none;
			margin-top: 2em;
			width: 10em;
			height: 2.25em;
			border-radius: 0.56em;
			color: white;
			font-weight: normal;
			text-transform: none;
			background-color: #253D8D;
			background-image: linear-gradient(#3E57AA, #253D8D);
			cursor: pointer;
			@include flex('center', 'center');
			&:hover {
				background-color: darken(#253D8D, 3%);
				background-image: linear-gradient(darken(#3E57AA, 3%), darken(#253D8D,3%));
			}
		}
	}
}

@media only screen and (max-aspect-ratio: 1/1) {
	.Game .Game-panel {
		width: 100%;
		height: auto;
		min-height: 100%;
		font-size: 10px;
		padding: 4em 2em;
		.Game-groups {
			font-size: 0.85em;
			margin: auto;
		}
	}
}