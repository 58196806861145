@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
.CookieConsent {
	position: absolute;
	width: 100%;
	bottom: 0;
	color: #253D8D;
	background: rgba(#C0CBE1, 0.8);
	height: 0;
	-webkit-animation: slideIn 0.5s linear 0.5s 1 forwards;
	animation: slideIn 0.5s linear 0.5s 1 forwards;
}

.CookieConsent-text {
	height: 1em;
	padding: 1em 10em 1em 1em;
	text-align: left;
	span {
		font-size: 0.8em;
	}
	a {
		font-size: 0.8em;
		color: #253D8D;
	}
}


@-webkit-keyframes slideIn {
	0% {height: 0;}
	100% {height: 4em;}
}
@keyframes slideIn {
	0% {height: 0;}
	100% {height: 4em;}
}


@media only screen and (max-aspect-ratio: 1/1) {
	.CookieConsent-text {
		padding: 0.5em 10em 0.5em 1em;
		line-height: 1.05;
	}
}