@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
.Stats {
	height: 100%;
	background-color: #282c34;
	.Stats-panel {
		position: relative;
		height: 100%;
		width: 40em;
		background-color: #253D8D;
		background-image: linear-gradient(to bottom left, #253D8D, #131F47);
		text-align: center;
		padding: 3.5em 2.4em;

		.Stats-backBtn {
			position: absolute;
			top: 1em;
			left: 1em;
			width: 2em;
			height: 1.85em;
			background-image: url('../../assets/images/icon-home.svg');
			background-position: center center;
			background-size: auto 90%;
			background-repeat: no-repeat;
			cursor: pointer;
			&:hover {
				background-size: auto 100%;
			}
		}

		.Stats-title {
			font-size: 3.5em;
			color: white;
			margin-bottom: 0.5em;
		}

		.Stats-navigation {
			width: 100%;
			@include flex('center', 'center');
			.Stats-navBtn {
				width: 50%;
				height: 2.25em;
				border-top-left-radius: 0.56em;
				border-top-right-radius: 0.56em;
				color: white;
				font-weight: normal;
				background-color: #253D8D;
				cursor: pointer;
				background-image: linear-gradient(#3E57AA, #253D8D);
				@include flex('center', 'center');
				@include no-select();
				@include opacity(0.5);
				&:hover {
					background-color: darken(#253D8D, 3%);
					background-image: linear-gradient(darken(#3E57AA, 3%), darken(#253D8D,3%));
				}	
				&.selected {
					@include opacity(1);
					background-color: white;
					border-bottom: none;
				}
				&:nth-child(2) {
					border-left: none;
					// &.selected {border-right: none;}
				}
			}
		}
		

		.Stats-users,
		.Stats-games {
			display: none;
			width: 100%;
			color: white;
			background-color: #131F47;
			padding: 0.5em;
			height: calc(100% - 7em);
			overflow: auto;
			@include custom-scrollbar(0.5em, rgba(#3E57AA, 0.25), #EFF2F9);
			table {
				width: 100%;
				thead td {
					font-weight: bold;
					width: 25%;
					text-align: center;
					&.clickable {
						cursor: pointer;
					}
				}
				tbody td {
					text-align: center;
				}
			}
			&.show {
				display: inline-block;
			}
		}
		.Stats-users table {
			thead td,
			tbody td {
				width: 25%;
				&:nth-child(1) {
					width: 50%;
					overflow: hidden;
					text-align: left;
				}
			}
		}
		.Stats-games table {
			thead td,
			tbody td {
				width: 12%;
				&:nth-child(1) {
					width: 20%;
				}
				&:nth-child(2) {
					width: 42%;
					overflow: hidden;
				}
			}
		}
		

		.Stats-downloadBtn {
			width: 15em;
			height: 2.25em;
			border-radius: 0.56em;
			margin-bottom: 1em;
			color: white;
			font-weight: normal;
			background-color: #253D8D;
			cursor: pointer;
			background-image: linear-gradient(#3E57AA, #253D8D);
			@include flex('center', 'center');
			@include no-select();
			&:hover {
				background-color: darken(#253D8D, 3%);
				background-image: linear-gradient(darken(#3E57AA, 3%), darken(#253D8D,3%));
			}	
		}
	}
}











