@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
.SelectedActions {
	position: absolute;
	top: 7.15em;
	right: 2em;
	width: 16.11em;
	background-color: rgba(#284B83, 0.4);
	border-radius: 0.33em;
	.SelectedActions-header {
		width: 100%;
		height: 2.56em;
		border-radius: 0.33em 0.33em 0 0;
		color: white;
		background-image: linear-gradient(#3E57AA, #253D8D);
		@include flex('center', 'center');
	}
	.SelectedActions-body {
		padding: 0.8em 0.6em;
		.SelectedActions-area {
			.SelectedActions-areaTitle {
				span {
					color: white;
					font-size: 0.75em;
					font-weight: normal;
				}
			}
			.SelectedActions-areaActions {
				margin: 0.5em 0;
				.SelectedActions-action {
					position: relative;
					width: 100%;
					height: 2.56em;
					margin-bottom: 0.5em;
					border-radius: 0.56em;
					background-image: linear-gradient(#3E57AA, #253D8D);
					color: white;
					padding: 0.5em;
					@include flex('center', 'center');
					span {font-size: 0.88em;}
					&::before {
						content: '';
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 0.4em;
						background-color: #253D8D;
						border-radius: 0.67em 0.67em 0 0;
					}
				}
			}
			&.wash-and-sterilization {
				.SelectedActions-areaActions .SelectedActions-action::before {
					background-color: #9C65F2;
				}
			}
			&.formulation-and-stabilization {
				.SelectedActions-areaActions .SelectedActions-action::before {
					background-color: #E3535F;
				}
			}
			&.filling-and-inspection {
				.SelectedActions-areaActions .SelectedActions-action::before {
					background-color: #DF8D25;
				}
			}
			&.office-and-storage {
				.SelectedActions-areaActions .SelectedActions-action::before {
					background-color: #4AAC59;
				}
			}
		}

		.SelectedActions-placeholder {
			width: 100%;
			height: 2.56em;
			margin-bottom: 0.5em;
			border-radius: 0.56em;
			color: #C0CFE1;
			padding: 0.5em;
			border: 1px dashed #C0CFE1;
			@include flex('center', 'center');
			span {
				font-size: 0.75em;
				font-weight: normal;
			}

		}
		.SelectedActions-confirmBtn {
			width: 50%;
			margin-top: 1em;
			margin-left: 50%;
			height: 2.25em;
			color: white;
			border-radius: 0.56em;
			@include flex('center', 'center');
			background-image: linear-gradient(#3E57AA, #253D8D);
			cursor: pointer;
			&:not(.disabled) {				
				&:hover {
					background-image: linear-gradient(darken(#3E57AA, 3%), darken(#253D8D, 3%));
				}
			}
			&.loading {
				background-image: url('../../assets/images/icon-loading.svg'), linear-gradient(#3E57AA, #253D8D);
				background-size: auto 80%, 100%;
				background-position: center right, center center;
				background-repeat: no-repeat;
			}
			&.disabled {
				opacity: 0.5;
				cursor: not-allowed;
				span {
					font-size: 0.89em;
				}
			}
		}
	}
}