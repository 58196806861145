@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
.RoundTrack {
	position: absolute;
	top: 4.7em;
	right: 2.25em;
	width: 15.75em;
	@include flex('space-between', 'center');
	.RoundTrack-round {
		width: 1.67em;
		height: 1.67em;
		border-radius: 2em;
		color: #FFFFFF;
		background-color: rgba(#284B83, 0.4);
		@include flex('center', 'center');
		span {
			font-size: 0.88em;
			font-weight: normal;
		}
		&.selected {
			color: #3E57AA;
			background-color: #EFF2F9;
			background-image: linear-gradient(#EFF2F9, #E0E5F3);
			span {font-weight: 700;}
		}
		&.blink {
			@include blink();
		}
	}
}