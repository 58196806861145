@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
.Graph {
	position: relative;
	width: 100%;
	height: 100%;
	.Graph-grid {
		line {
			stroke: #C0CBE1;
			stroke-width: 1px;
		}
	}
	.Graph-axis {
		> line {
			stroke: #C0CBE1;
			stroke-width: 3px; 
		}
		.Graph-tic {
			&.hidden {display: none;}
			line {
				stroke: #C0CBE1;
				stroke-width: 3px;
			}
			text {
				font-size: 1.2em;
				fill: #3E57AA;
			}
			&.bottom {transform: translate(0, -8px);}
			// &.left {transform: translate(8px, 0);}
			// &.right {transform: translate(-8px, 0);}
		}
	}
	.Graph-line {
		stroke-width: 3px;
		&.production {
			stroke: #1DB5D5;
		}
		&.cost {
			stroke: #20CBA4;
		}
		&.unitcost {
			stroke: #417BD8;
		}
		&.unitcost2 {
			stroke: #417BD8;
			stroke-dasharray: 8px;
		}
		&.demand {
			stroke: #BED2F3;
		}
		&.demand2 {
			stroke-width: 2px;
			stroke: #BED2F3;
		}
		&.vertical {
			stroke-width: 1px;
			stroke: #AEC3EB;
			stroke-dasharray: 8px;
		}
	}
	.Graph-rect {
		fill: transparent;
	}
	.Graph-marker {
		&.production {
			fill: #1DB5D5;
		}
		&.unitcost,
		&.unitcost2 {
			fill: #417BD8;
		}
		&.cost {
			fill: #20CBA4;
		}
		&.demand {
			fill: #BED2F3;
		}
	}

	.Graph-toolTip {
		position: relative;
		width: 6em;
		height: 2em;
		color: #3E57AA;
		background-color: white;
		border-radius: 0.44em;
		padding: 0 0.5em;
		transform: translate(-3em, -2.75em);
		opacity: 0;
		@include fadeIn();
		@include flex('center', 'center');
		@include box-shadow(0, 3px, 6px, 0, rgba(black, 0.16));
		span {
			display: block;
			font-size: 0.75em;
			&:nth-of-type(1) {
				font-weight: bold;
			}
		}
		&.demand2,
		&.unitcost2 {
			width: 10em;
			transform: translate(-4em, -2.75em);
		}
		&.demand2 {
			transform: translate(-3.25em, -2.75em);
		}
		&:after {
			content: '';
			position: absolute;
			left: calc(50% - 0.5em);
			top: calc(100% - 1px);
			width: 0;
			height: 0;
			border-top: 0.5em solid white;
			border-left: 0.5em solid transparent;
			border-right: 0.5em solid transparent;
		}
	}
}