@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
.ChangeParameter {
	@include flex('space-between', 'center');
	.ChangeParameter-costPerUnit {
		span {font-size: 0.88em;}
	}
	.ChangeParameter-controls {
		@include flex('center', 'center');
		.ChangeParameter-button {
			width: 1.11em;
			height: 1.11em;
			background-image: url('../../assets/images/icon-minus-btn.svg');
			background-size: contain;
			background-position: center center;
			background-repeat: no-repeat;
			cursor: pointer;
			&.increase {
				background-image: url('../../assets/images/icon-plus-btn.svg');
				background-position: center center;
			}
			&.disabled {
				opacity: 0.5;
				cursor: not-allowed;
			}
		}
		.ChangeParameter-value {
			width: 1.4em;
			height: 1.4em;
			margin: 0 0.15em;
			background-color: rgba(#FFFFFF, 0.5);
			border: 2px solid rgba(#3E57AA, 0.5);
			border-radius: 0.25em;
			@include flex('center', 'center');
			@include no-select();
			span {font-size: 0.88em;}
		}
	}
	&.confirmed {
		.ChangeParameter-controls {
			.ChangeParameter-value {
				color: #3E57AA;
				background-color: #C0CFE1;
				border-style: none;
			}
		}
	}
}