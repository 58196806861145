@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
.Navigation {
	position: absolute;
	left: 2em;
	right: 2em;
	top: 0.6em;
	height: 3.25em;
	
	border-radius: 0.75em;
	@include flex('center', 'center');
	.Navigation-title {
		display: inline-block;
		height: 100%;
		width: 15.25em;
		color: white;
		background-image: linear-gradient(#3E57AA, #253D8D);
		border-radius: 0.75em 0 0 0.75em;
		padding: 0.5em 0.5em 0.5em 1em;
		.Navigation-titleText {
			display: inline-block;
			vertical-align: center;
			width: 50%;
			font-size: 1.4em;
		}
		.Navigation-titleUC {
			display: inline-block;
			vertical-align: center;
			height: 100%;
			width: 50%;
			border-radius: 0.56em;
			background-color: #203479;
			.Navigation-titleUCValue {
				height: 100%;
				@include flex('center', 'center');
				span {
					font-size: 1.25em;
					margin-right: 0.25em;
				}
			}
		}
	}

	.Navigation-items {
		position: relative;
		width: calc(100% - 15.25em);
		height: 100%;
		background-image: linear-gradient(#EFF2F9, #EFF2F9);
		border-radius: 0 0.75em 0.75em 0;
		@include flex('space-evenly', 'center');
		.Navigation-item {
			position: relative;
			height: 100%;
			color: rgba(#3E57AA, 0.6);
			cursor: pointer;
			@include flex('center', 'center');
			&:hover,
			&.selected {
				color: #3E57AA;
			}
		}
		.Navigation-selectedMarker {
			position: absolute;
			top: 0;
			left: 0;
			width: 4em;
			height: 100%;
			@include transition(all, .25s, ease-in-out);
			&::before,
			&::after {
				content: '';
				position: absolute;
				top: 0;
				left: -1em;
				width: calc(100% + 2em);
				height: 0.3em;
				background-color: #253D8D;
				border-radius: 0 0 0.56em 0.56em;
			}
			&::after {
				top: auto;
				bottom: 0;
				border-radius: 0.56em 0.56em 0 0;
			}
		}
	}


	&.wash-and-sterilization {
		.Navigation-title .Navigation-titleUC {
			background-color: #9C65F2;
		}
		.Navigation-items .Navigation-selectedMarker {
			&::before,
			&::after {
				background-color: #9C65F2;
			}
		}
	}
	&.formulation-and-stabilization {
		.Navigation-title .Navigation-titleUC {
			background-color: #E3535F;
		}
		.Navigation-items .Navigation-selectedMarker {
			&::before,
			&::after {
				background-color: #E3535F;
			}
		}
	}
	&.filling-and-inspection {
		.Navigation-title .Navigation-titleUC {
			background-color: #DF8D25;
		}
		.Navigation-items .Navigation-selectedMarker {
			&::before,
			&::after {
				background-color: #DF8D25;
			}
		}
	}
	&.office-and-storage {
		.Navigation-title .Navigation-titleUC {
			background-color: #4AAC59;
		}
		.Navigation-items .Navigation-selectedMarker {
			&::before,
			&::after {
				background-color: #4AAC59;
			}
		}
	}
	
	
}