@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
.CreateGamePopup {
	position: fixed;
	top: 0;
	left: 0;
	width: 40em;
	bottom: 0;
	background: rgba(white, 0.12);
	backdrop-filter: blur(4px);
	@include flex('center', 'center');

	.CreateGamePopup-content {
		position: relative;
		width: 18em;
		color: white;
		background-color:rgba(#131F47, 0.8);
		border-radius: 0.67em;
	
		.CreateGamePopup-header {
			position: relative;
			padding: 1.4em 0 1em 0;
			.CreateGamePopup-title {
				font-size: 1.2em;
				text-align: center;
			}
		}	

		.CreateGamePopup-body {
			padding: 0 1em 1em 1em;
			text-align: left;
			.CreateGamePopup-form {
				.CreateGamePopup-label {
					font-size: 0.75em;
					font-weight: normal;
					margin-top: 1.25em;
					margin-bottom: 0.2em;
				}
				input {
					display: block;
					width: 100%;
					height: 2.25em;
					border: none;
					border-radius: 0.56em;
					padding: 0.56em 1em;
					margin: auto;
					// margin-bottom: 1em;
					background-color: #C0CBE1;
					color: #253D8D;
					font-weight: normal;
					&::placeholder {color: #3E57AA;}
				}
				input:disabled {
					color: black;
					background-color: rgb(226, 225, 225);
				}
				.react-datepicker-wrapper {
					width: 100%;
				}
				.CreateGamePopup-errMsg {
					color: red;
				}

			}
			.CreateGamePopup-buttons {
				margin-top: .25em;
			}
		}
	}
}

@media only screen and (max-aspect-ratio: 1/1) {
	.CreateGamePopup {
		width: 100%;
	}
}