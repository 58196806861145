@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
.ReportPopup {
	position: fixed;
	z-index: 10;
	left: 0;
	bottom: 0;
	width: 0;
	height: 0;
	background: rgba(white, 0.12);
	@include flex('center', 'center');
	@include origin(3.5em, calc(100% - 3.5em));
	@include transition('all', 0.25s, ease-out);
	@include transition-delay(0.15s);

	.ReportPopup-content {
		position: relative;
		width: calc(100% - 4em);
		height: calc(100% - 4em);
		color: #3E57AA;
		background-color:#EFF2F9;
		background-image: linear-gradient(#EFF2F9, #E0E5F3);
		border-radius: 0.67em;
		overflow: hidden;
		&::before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 0.4em;
			background-color: #3E57AA;
			border-radius: 0.67em 0.67em 0 0;
		}
	
		.ReportPopup-header {
			position: relative;
			padding: 1.4em 2em 0.25em 2em;
			@include opacity(0);
			@include transition('all', 0.15s, ease-out);
			@include transition-delay(0s);
			.ReportPopup-closeBtn {
				position: absolute;
				top: 1em;
				right: 1em;
				width: 1em;
				height: 1em;
				background-image: url('../../assets/images/icon-close.svg');
				background-size: 90% auto;
				background-position: center center;
				background-repeat: no-repeat;;
				cursor: pointer;
				&:hover {
					background-size: 100% auto;
				}
			}
			.ReportPopup-title {
				font-size: 1.2em;
				// text-align: center;
			}
		}	

		.ReportPopup-body {
			position: relative;
			width: 100%;
			height: calc(100% - 3em);
			padding: 0 1em 1em 1em;
			text-align: left;
			@include opacity(0);
			@include transition('all', 0.15s, ease-out);
			@include transition-delay(0s);

			.ReportPopup-text {
				position: absolute;
				left: 1em;
				top: 0;
				width: 22em;
				height: calc(100% - 5.5em);
				padding: 1em;
				font-weight: 400;
				margin-bottom: 1em;
				overflow: auto;
				@include custom-scrollbar(0.5em, rgba(#3E57AA, 0.25), #EFF2F9);
				p:nth-child(1) {
					margin-top: 0;
				}
				table {
					width: 100%;
					thead {
						font-weight: bold;
					}

				}
				.ReportPopup-additionalProduction,
				.ReportPopup-GMPactionTaken,
				.ReportPopup-GMPactionNotTaken,
				.ReportPopup-oldMachines,
				.ReportPopup-noOldMachines {
					display: none;
				}
				&.demandNotMet .ReportPopup-additionalProduction {display: inline;}	
				&.GMPActionTaken .ReportPopup-GMPactionTaken {display: inline;}
				&.GMPActionNotTaken .ReportPopup-GMPactionNotTaken {display: inline;}
				&.oldMachines .ReportPopup-oldMachines {display: inline;}
				&.noOldMachines .ReportPopup-noOldMachines {display: inline;}
			}
			.ReportPopup-graph {
				position: absolute;
				right:  0;
				top: 0;
				width: calc(100% - 24em);
				height: calc(100% - 5.5em);
				margin-bottom: 1em;
				text-align: center;
			}
			.ReportPopup-buttons {
				position: absolute;
				left: 0;
				bottom: 1.5em;
				width: 100%;
				height: 2.25em;
				padding: 0 2em;
				@include flex('flex-end', 'center');
				.ReportPopup-button {
					position: relative;
					width: 14.56em;
					height: 2.25em;
					padding-left: calc(2.5em + 2px);
					margin-left: 1em;
					color: #FFFFFF;
					background-color: #3F59A8;
					border-radius: 0.67em;
					border: 1px solid #3F59A8;
					box-sizing: border-box;
					cursor: pointer;
					@include flex('left', 'center');
					@include no-select();
					span {
						font-size: 0.95em;
					}
					&::after {
						content: '';
						width: 1.33em;
						height: 1.33em;
						border-radius: 1.33em;
						position: absolute;
						left: calc(0.6em + 2px);
						top: calc(0.46em - 1px);
						background-color: white;
					}
					&.production::after {background-color: #1DB5D5;}
					&.costs::after {background-color: #20CBA4;}
					&.unit-cost::after {background-color: #417BD8;}
					&.game-score-unit-cost::after {background-color: #1DB5D5;}
					&.demand::after {
						width: calc(1.33em - 4px);
						height: calc(1.33em - 4px);
						background-color: #D8E9FF;
						border: 2px solid #BED2F3;
					}
					&.off {
						color: #3E57AA;
						background-color: transparent;
						border: 1px solid #3F59A8;
					}
				}
			}
		}
	}

	&--show {
		width: 100%;
		height: 100%;
		backdrop-filter: blur(4px);
		@include transition('all', 0.25s, ease-out);
		@include transition-delay(0s);
		.ReportPopup-content {
			.ReportPopup-header,
			.ReportPopup-body {
				@include opacity(1);
				@include transition('all', 0.25s, ease-out);
				@include transition-delay(0.25s);
			}
		}
	}
}