@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
.PauseOverlay {
	position: fixed;
	z-index: 5;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba(#1E3867, 0.7);
	// backdrop-filter: blur(4px);
	@include flex('center', 'center');

	.PauseOverlay-content  {
		color: white;
		font-size: 1.5em;
	}
}